
$primary: #00669d; /* MAIN COLOR */
$secondary: #00669d; /* SECONDARY COLOR */
$blk: #333; /* DARK GRAY, REASON WHY IT'S SET TO #333 IS SO YOU CAN DARKEN IT IF NEEDED USING darken($blk, 10%); FOR EXAMPLE. YOU COULD ALSO USE 2 VARIABLES INSTEAD */
$wht: #fafafa; 
$border-radius: 5px; /* USE THIS TO SET THE BORDER RADIUS FOR BUTTONS */
$footerLinks: #fafafa;

nav {
	z-index: 1000;
}



.navbar {
	border-radius: 0;	
	.navbar-nav {
	    > li > a {
			text-align: center;
			height: 80px;
			display: flex;
			align-items: center;
			margin-top: -2px;
			
		    &:hover, &:focus {
		    	background: $primary;
		    	color: lighten($primary, 50%);
		    }
		}
	}
}

 
.navbar-toggle {
    margin: 30px 15px 8px 0px;
    border: 1px solid transparent;
    border-radius: 4px;
	
	@media (max-width: 388px) {
		margin-top: 14px;

	}
}


.navbar-default {
	border: none;
	border-radius: 0px;
}

.navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus
{
	background: #00669d;
	color: #fff
}


@media (max-width: 767px) {
  .navbar .navbar-nav > li > a {
    height: 40px;
    display: block;
	text-align: center;
  }
}



.logo {
	max-width: 60%;
}


@media (max-width: 991px) {
  .navbar-header {
    width: 30%;
  }

  .logo {
    max-width: 100%;
    margin-top: 0.5em;
  }
}


@media (max-width: 767px) {
  .navbar-header {
    width: 100%;
  }
  
  .navbar-toggle {
  	margin-top: 1em;
  }

  .logo {
    max-width: 40%;
    margin-left: 1em;
	margin-top: 0.25em;
	margin-bottom: 0.25em;
  }
}

@media (max-width: 420px) {
	.logo {
		max-width: 50%;
		margin-top: 0.25em;
		margin-bottom: 0.25em;
	}
}

/** LOGIN FORM **/

@mixin btn1 {
	text-align: center;
	margin: 0 auto;
	border: 1px solid $primary;
	border-radius: $border-radius;
	background: $primary;
	font-size: 1.75em;
	color: $wht;
	padding: 0.5em 2em;

	&:hover {
		background: lighten($primary,10%);
		color: lighten($wht, 10%);
		box-shadow: 0px 0px 3px lighten($blk,10%);
	}
}

@mixin btn2 {
	@include btn1;
	background: darken($wht, 5%);
	color: $primary;
	border: none;

	&:hover {
		background: $wht;
		color: $primary;
		border: none;
	}
}

.modal-dialog {
	width: 350px;
	text-align: center;
	margin: 6em auto;
	
	.close {display: none;}

	.modal-content {
		color: $primary;
		
		h2 {
			text-align:center;
		}
	}

	input {
		border: none;
		border-bottom: 1px solid darken($primary,20%);
		text-align: center;
	}
	
	button {
		@include btn2;
		font-size: 1em;
		display: block;
		width: 50%;
	}

	input[type="submit"] {
		@include btn1;
		display: block;
		width: 75%;	
	}

}

.modal-header, .modal-footer {
	background: $primary;
	color: $wht;
}

input#username {
	margin-bottom: 20px;
}

/** END LOGIN FORM **/

footer {
	padding: 50px 0px 20px;
	background: $primary;
	color: $footerLinks;

	a {
		color: $footerLinks;

		&:active, &:focus {
			color: $footerLinks;
			text-decoration: none;
		}

		&:hover {
			color: lighten($footerLinks, 10%);
		}
	}
}


/** STYLE FOR THIS SITE **/
.row { margin: 0; }

.banner {
	background: url(../img/banner.jpg) no-repeat;
	background-position: 65% top;
	background-size: cover;
	color: $wht;
	padding: 15em 0em;
	transition: 1s ease-in;
}

.banner2 {
	background: url(../img/banner2.jpg) no-repeat top;
	background-size: 100%;
	color: $wht;
	padding: 15em 0em; 
	transition: 1s ease-in;
	
	&:hover {
		background-size: 125%;
	}

	@media (max-width: 1200px) {
		padding: 7em 0em;
		background-size: 116%;
	}

	@media (max-width: 991px) {
		background-size: cover;

			&:hover {
				background-size: cover;
			}
	}
}

h1 {
	text-transform: uppercase;
	font-size: 4em;
}


h2 {
	line-height: 1.5em;
}


.marTop { margin-top: 3em; }
.pad { padding: 10em 0em; }

.whtTxt {
	color: $wht;
	text-shadow: 0px 0px 5px $blk;
}

.primaryTxt {
	color: $primary;
	text-shadow: 0px 0px 5px $primary;
}

.btn {
	@include btn1;
	margin: 2em 0em;
}

hr {
	width: 50%;
	border: 1px solid $primary;
}

.imgBack1 {
	background: url(../img/back1.jpg) no-repeat top;
	background-size: 100%;
	padding: 12em 0em;
	transition: 1s ease-in;
	
	&:hover {
		background-size: 125%;
	}



	@media (max-width: 1350px) {
	background-size: cover;

		&:hover {
			background-size: cover;
		}
	}

	@media (max-width: 1200px) {
		padding: 7em 0em;
	}
}

.imgBack2 {
	background: url(../img/back2.jpg) no-repeat top;
	background-size: 100%;
	padding: 12em 0em;
	transition: 1s ease-in;
	
	&:hover {
		background-size: 125%;
	}
	@media (max-width: 1350px) {
	background-size: cover;

		&:hover {
			background-size: cover;
		}
	}

	@media (max-width: 1200px) {
		padding: 7em 0em;
	}


}


@media (max-width: 991px) {
  h1 {
    font-size: 2.5em ;
  }

  .banner {
  	padding: 8em 0em;
  }
}
